.footer {
  position: sticky;
  bottom: 0;
  z-index: 4;
}

@media (max-width: 425px) {
  .footer {
    position: static;
    bottom: 0;
    z-index: 4;
  }
}
