@charset "utf-8";
/* CSS Document */

@charset "utf-8";
/* CSS Document */

body{
	color: hsla(0,0%,20%,1.00);
}


.header-img-stretch {
	width: 100%;
	object-fit: cover;
}

footer{
	height: 100px;
	padding: 40px 0px;
}

.vh-fill {
	min-height: calc(100vh - 380px);
}

.bg-blue {
    background-color: #0071B9;
}


.fs-24 {
	font-size: 24px;
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.divider-light {
	background-color: #E0E0E5;
	height: 2px;
	margin-bottom: 14px;
}

.divider-orange {
	background-color: #F18B21;
	height: 3.5px;
	width: 36px;
}

.divider-orange-lg {
	background-color: #F18B21;
	height: 3px;
	width: 100px;
}


.bg-banner1 {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
}

.bg-SunPowerBlack {
	background-color: hsla(0,0%,0%,0.60);
	color:#FFFFFF;
	text-align: center;
}

.bg-SunPowerBlue {
	background-color: #0076be;
}


/*Nav*/
.nav-link.active {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}

.nav-link:hover {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}
/*Nav*/

/*Tabs*/

.nav-link.tab-title {
	color: #858585;
	background-color: #EEEEEE;
}

.nav-link.tab-title:hover {
	color: #E2620E;
}

.nav-link.tab-title.active {
	color: #E2620E;
}

/*Tabs*/


/*Buttons*/

.OrangeBtn {
	font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
	border: none;
}
.OrangeBtn:hover {
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}

.OrangeBtn:focus {
	color: hsla(0,0%,100%,1.00);
    background: #E2620E;
}


.BlueBtn {
	font-weight: 500;
	background-color: #0076BE;
	color: #FFFFFF;
}

.BlueBtn:hover {
	background-color:#39607A;
	color: #FFFFFF;
}

.BlueBtn:active {
	background-color:#39607A;
	color: #FFFFFF;
}
.BlueBtn:focus {
	background-color:#39607A;
	color: #FFFFFF;
}


.BlueBtnOutline{
	font-weight: 500;
	border: 1px solid #0076BE;
	color: #0076BE;
}

.BlueBtnOutline:hover{
	background-color:#0076BE;
	color: #FFFFFF;
}

.BlueBtnOutline:focus{
	background-color:#39607A;
	color: #FFFFFF;
}

.BlueBtnOutline:active{
	background-color:#39607A;
	color: #FFFFFF;
}

.BlueRadioBtn:hover {
	border: 1px solid #0076BE;
	color: #0076BE;
}

.BlueRadioBtn.active {
	border: 1px solid #0076BE;
	color: #0076BE;
}

/*Buttons*/

/*Links*/

.OrangeLink {
	color: #F18B21;
}
.OrangeLink:hover {
	color: #E2620E;
}

.BlueLink {
	color: #0076BE;
}

.BlueLink:hover {
	color: #39607A;
}


/*Typography*/

h1, h2, h3, h4, h5 {
	font-weight: 500;
}